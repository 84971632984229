/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export type AccumulatedFeePercentageValue = UtilRequiredKeys<DeliveryFeeValue, "TypeDiscriminator"> & {
  /** @format double */
  percentage: number;
  TypeDiscriminator: string;
};

export interface AddOrUpdateCustomerDeliveryInformationTemplateRequest {
  /**
   * @minLength 1
   * @maxLength 250
   */
  title: string;
  /** @format uuid */
  id?: string | null;
  /** @format uuid */
  customerId: string;
  description?: string | null;
  shipmentPayer: ShipmentPayer;
  deliveryType: DeliveryType;
  senderAddress: AddressViewObject | ShipmentRequestDeliveryInformation;
  receiverAddress: AddressViewObject | ShipmentRequestDeliveryInformation;
  billingAddress?: AddressViewObject | ShipmentRequestDeliveryInformation | null;
  pickupTime?: TimeOfDay | null;
  deliveryTime?: TimeOfDay | null;
  deliveryItems: CustomerDeliveryItemTemplateViewObject[];
}

export interface AddOrUpdateDeviationRequest {
  /** @format uuid */
  taskId: string;
  /** @format uuid */
  deviationTypeId: string;
  description?: string | null;
  imageUrls?: string[] | null;
  /** @format double */
  amount?: number | null;
}

export interface AddUsersToChatRequest {
  /** @format uuid */
  chatId: string;
  userIds: string[];
}

export interface AddressViewObject {
  companyName: string;
  /** @minLength 1 */
  street: string;
  /** @format int32 */
  postalCode: number;
  /** @minLength 1 */
  city: string;
  /** @minLength 1 */
  country: string;
  phone?: string | null;
  /** @maxLength 500 */
  description?: string | null;
  /** @maxLength 100 */
  att?: string | null;
  email?: string | null;
  cvr?: string | null;
}

export interface BooleanArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: boolean[];
}

export interface BooleanClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: boolean;
}

export type BusinessEntityCustomerDeliveryFeeViewObject = DeliveryFeeViewObject;

export type BusinessEntityDeliveryHubConnectionViewObject = HubConnectionViewObject;

export type BusinessEntityDeliveryTaskImportConfigurationViewObject = EntityViewObject & {
  name: string;
  configuration: TaskImportConfiguration;
};

export type BusinessEntityHubConnectionViewObject = HubConnectionViewObject & {
  viewingDashboardForDate?: string | null;
};

export interface BusinessEntityResourceDriverLogsViewModel {
  /** @format int32 */
  total: number;
  logs: BusinessEntityUserDriverLogViewObject[];
}

export interface BusinessEntityResourceDriverLogsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityResourceDriverLogsViewModel;
}

export interface BusinessEntitySettingsViewObject {
  dashboardColorSettings: DashboardColorSettings;
  shippingColorSettings: ShippingColorSettings;
  deliverySettings: DeliverySettings;
  shippingSettings: ShippingSettings;
  rentalSettings: RentalSettings;
  businessSettings: BusinessSettings;
  /** @format int32 */
  datePickerMinuteInterval: number;
  /** @format int32 */
  shipmentPdfPageCount: number;
}

export interface BusinessEntitySiteDefinitionViewObject {
  domain: string;
}

export type BusinessEntityStorageLockViewObject = GuidEntityLockViewObject;

export type BusinessEntityStorageViewObject = EntityViewObject & {
  /** @format uuid */
  businessEntityId: string;
  name: string;
  description?: string | null;
  isDefault: boolean;
  address: AddressViewObject | ShipmentRequestDeliveryInformation;
  lock?: BusinessEntityStorageLockViewObject | null;
} & {
  marked: boolean;
  dragging: boolean;
  sort: number;
};

export interface BusinessEntityStoragesViewModel {
  storages: BusinessEntityStorageViewObject[];
}

export interface BusinessEntityStoragesViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityStoragesViewModel;
}

export interface BusinessEntityUserDriverLogBreakViewObject {
  /** @format int32 */
  id: number;
  duration: DurationViewObject;
  startLocation?: GeoLocation | null;
  endLocation?: GeoLocation | null;
  description?: string | null;
  inProgress: boolean;
  finished: boolean;
}

export interface BusinessEntityUserDriverLogPreStartCheckEntryViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  userId: string;
  /** @format uuid */
  businessEntityId: string;
  /** @format uuid */
  driverLogId: string;
  /** @format uuid */
  preStartCheckId?: string | null;
  description: string;
  imageUrl?: string | null;
}

export interface BusinessEntityUserDriverLogPreStartCheckViewModel {
  preStartChecks: BusinessEntityUserDriverLogPreStartCheckViewObject[];
}

export interface BusinessEntityUserDriverLogPreStartCheckViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityUserDriverLogPreStartCheckViewModel;
}

export type BusinessEntityUserDriverLogPreStartCheckViewObject = EntityViewObject & {
  description: string;
  /** @format int32 */
  order: number;
  enabled: boolean;
  allowImage: boolean;
  requireImage: boolean;
  resourceIds: string[];
  validFrom?: string | null;
  validTo?: string | null;
};

export interface BusinessEntityUserDriverLogTrackingStatisticsViewObject {
  /** @format double */
  totalKM: number;
  /** @format double */
  totalMinutes: number;
}

export interface BusinessEntityUserDriverLogViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  userId: string;
  /** @format uuid */
  scheduleEntryId: string;
  /** @format uuid */
  resourceId: string;
  duration: DurationViewObject;
  trackingStatistics: BusinessEntityUserDriverLogTrackingStatisticsViewObject;
  startLocation?: GeoLocation | null;
  endLocation?: GeoLocation | null;
  currentLocation?: GeoLocation | null;
  inProgress: boolean;
  isOnBreak: boolean;
  finished: boolean;
  breaks: BusinessEntityUserDriverLogBreakViewObject[];
  preStartCheckEntries: BusinessEntityUserDriverLogPreStartCheckEntryViewObject[];
}

export interface BusinessEntityUserDriverLogViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityUserDriverLogViewObject;
}

export interface BusinessEntityUserDriverLogsViewModel {
  logs: BusinessEntityUserDriverLogViewObject[];
}

export interface BusinessEntityUserDriverLogsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityUserDriverLogsViewModel;
}

export interface BusinessEntityUserLocationTrackingLogViewModel {
  logs: BusinessEntityUserLocationTrackingLogViewObject[];
}

export interface BusinessEntityUserLocationTrackingLogViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityUserLocationTrackingLogViewModel;
}

export interface BusinessEntityUserLocationTrackingLogViewObject {
  /** @format uuid */
  userId: string;
  /** @format uuid */
  driverLogId?: string | null;
  timestamp: string;
  /** @format double */
  latitude: number;
  /** @format double */
  longitude: number;
}

export interface BusinessEntityUserLoginHistoryViewModel {
  loginHistories: BusinessEntityUserLoginHistoryViewObject[];
}

export interface BusinessEntityUserLoginHistoryViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityUserLoginHistoryViewModel;
}

export type BusinessEntityUserLoginHistoryViewObject = UserLoginHistoryViewObject & {
  /** @format uuid */
  businessEntityId: string;
};

export enum BusinessEntityUserRole {
  None = "None",
  Carrier = "Carrier",
  Moderator = "Moderator",
  Administrator = "Administrator",
}

export interface BusinessEntityUserViewModel {
  user: EmployeeViewObject | UserViewObject;
}

export interface BusinessEntityUserViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityUserViewModel;
}

export interface BusinessEntityUsersViewModel {
  users: (EmployeeViewObject | UserViewObject)[];
}

export interface BusinessEntityUsersViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: BusinessEntityUsersViewModel;
}

export type BusinessEntityViewObject = EntityViewObject & {
  name: string;
  description?: string | null;
  email: string;
  phone?: string | null;
  logoUrl: string;
  dashboardLogoOverride: boolean;
  dashboardRouteAreaAccess: DashboardRouteAreaAccess;
  address: AddressViewObject | ShipmentRequestDeliveryInformation;
  openingHours: OpeningHoursViewObject;
  settings: BusinessEntitySettingsViewObject;
  siteDefinition: BusinessEntitySiteDefinitionViewObject;
  customerDeliveryFees: BusinessEntityCustomerDeliveryFeeViewObject[];
};

export interface BusinessSettings {
  loadTypes: string[];
  /** @minLength 1 */
  smsAccessToken: string;
  /** @format int32 */
  scheduleDaysLookAhead: number;
  scheduleDaysLookAheadSkipWeekends: boolean;
  scheduleDaysLookAheadShowDriverName: boolean;
  defaultStorageLocation: StorageLocation;
  allowOverridingScheduleTime: boolean;
  showAwaitingStorageInStorage: boolean;
  playSoundWhenChatMessageReceived: boolean;
  /** @format int32 */
  trackingLogsExpirationDays: number;
  /** @format int32 */
  minutesSinceLastGpsUpdateWarning: number;
  canUseRoutePlanner: boolean;
  enableDeliveryTemplates: boolean;
}

export interface ChangePasswordRequest {
  /** @minLength 1 */
  currentPassword: string;
  /** @minLength 1 */
  newPassword: string;
  /** @minLength 1 */
  newPasswordConfirm: string;
}

export type CompleteTaskRequest = TaskProgressRequest;

export interface ContactViewObject {
  /** @format int32 */
  id: number;
  name: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface CreateChatRequest {
  members: string[];
  /** @maxLength 255 */
  title?: string | null;
}

export type CustomerAddressEquals = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  scope: CustomerAddressEqualsEvaluationScope;
  TypeDiscriminator: string;
};

export enum CustomerAddressEqualsEvaluationScope {
  None = "None",
  Sender = "Sender",
  Receiver = "Receiver",
  Billing = "Billing",
}

export interface CustomerAddressViewObject {
  /** @format int32 */
  id: number;
  address: AddressViewObject | ShipmentRequestDeliveryInformation;
  created: string;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
  isCustomerAddress: boolean;
}

export interface CustomerAddressesViewModel {
  addresses: CustomerAddressViewObject[];
}

export type CustomerContactViewObject = ContactViewObject & {
  /** @format uuid */
  customerId: string;
};

export type CustomerDeliveryFeeViewObject = DeliveryFeeViewObject & {
  /** @format uuid */
  customerId: string;
};

export interface CustomerDeliveryFeeViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerDeliveryFeeViewObject[];
}

export interface CustomerDeliveryFeesRequest {
  customerIds: string[];
}

export interface CustomerDeliveryInformationDistanceMatrixViewObject {
  /** @format int32 */
  distanceInMeters: number;
  /** @format int32 */
  estimatedDurationMinutes: number;
}

export interface CustomerDeliveryInformationDurationViewObject {
  startDate: string;
  endDate: string;
}

export interface CustomerDeliveryInformationFeeViewObject {
  name: string;
  /** @format double */
  fee: number;
  /** @format int32 */
  order: number;
}

export interface CustomerDeliveryInformationServiceFeeViewObject {
  /** @minLength 1 */
  name: string;
  /** @format double */
  fee: number;
  /** @format int32 */
  order: number;
  calculationEntry: ServiceFeeCalculationEntry;
}

export interface CustomerDeliveryInformationTemplateViewObject {
  /** @format uuid */
  id: string;
  title: string;
  created: string;
  /** @format uuid */
  createdBy?: string | null;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
  isCustomerOnly: boolean;
  deliveryInformation: CustomerDeliveryInformationViewObject;
}

export interface CustomerDeliveryInformationTemplateViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerDeliveryInformationTemplateViewObject[];
}

export interface CustomerDeliveryInformationTemplateViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerDeliveryInformationTemplateViewObject;
}

export interface CustomerDeliveryInformationViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  customerId: string;
  senderAddress: AddressViewObject | ShipmentRequestDeliveryInformation;
  receiverAddress: AddressViewObject | ShipmentRequestDeliveryInformation;
  billingAddress?: AddressViewObject | ShipmentRequestDeliveryInformation | null;
  /** @format double */
  deliveryPrice: number;
  /** @format double */
  totalDeliveryPrice: number;
  /** @format double */
  total: number;
  /** @format double */
  discountPercentage?: number | null;
  /** @format double */
  discountPrice: number;
  /** @format double */
  shipmentWeight: number;
  deliveryFees: CustomerDeliveryInformationFeeViewObject[];
  serviceFees: CustomerDeliveryInformationServiceFeeViewObject[];
  duration?: CustomerDeliveryInformationDurationViewObject | null;
  /** @format double */
  hourPrice?: number | null;
  /** @format double */
  totalHourPrice?: number | null;
  /** @format double */
  fullLoadPrice?: number | null;
  /** @format double */
  weightPrice: number;
  /** @format double */
  totalCubicMetre: number;
  /** @format double */
  totalLoadMetre: number;
  created: string;
  deliveryDateTime?: string | null;
  pickupDate?: string | null;
  deliveryDate?: string | null;
  deliveredDate?: string | null;
  shipmentPayer: ShipmentPayer;
  deliveryType: DeliveryType;
  description?: string | null;
  isSenderOrReceiverCustomer: boolean;
  isDeliveryPriceUnavailable: boolean;
  requiresManualPriceUpdate: boolean;
  distanceMatrix?: CustomerDeliveryInformationDistanceMatrixViewObject | null;
  deliveryItems: DeliveryItemViewObject[];
}

export interface CustomerDeliveryItemTemplateViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  shipmentTypeId: string;
  /** @format int32 */
  amount: number;
  /** @format double */
  cost?: number | null;
  /** @format double */
  weightKG?: number | null;
  /** @format double */
  heightCM?: number | null;
  /** @format double */
  lengthCM?: number | null;
  /** @format double */
  widthCM?: number | null;
  /** @format double */
  cubicMetre?: number | null;
  /** @format double */
  loadMetre?: number | null;
  description?: string | null;
  reference?: string | null;
}

export interface CustomerDeliveryPriceConfigurationViewObject {
  /** @format uuid */
  id: string;
  title: string;
  activeFrom: string;
  activeTo?: string | null;
  isActive: boolean;
  /** @format double */
  discountPercentage: number;
  shipmentWeightPriceRanges: CustomerShipmentWeightPriceRangeViewObject[];
  postalCodePriceRanges: CustomerPostalCodePriceRangeViewObject[];
  shipmentTypeWeightPriceRanges: CustomerShipmentTypeWeightPriceRangeViewObject[];
  hourPriceRanges: CustomerHourPriceRangeViewObject[];
}

export interface CustomerHourPriceRangeViewObject {
  /** @format uuid */
  id: string;
  /** @format double */
  hourFrom: number;
  /** @format double */
  hourTo: number;
  /** @format double */
  cost: number;
}

export type CustomerHubConnectionViewObject = HubConnectionViewObject;

export interface CustomerHubUserCustomerHubConnectionHubUserViewObject {
  /** @format uuid */
  userId: string;
  location?: GeoLocation | null;
  locationTrackingIsAvailable: boolean;
  lastLocationPing?: string | null;
  activity?: string | null;
  lastActivity: string;
  lastChannel?: string | null;
  lastAppVersionId?: string | null;
}

export type CustomerHubUserViewObject = CustomerHubUserCustomerHubConnectionHubUserViewObject & {
  /** @format uuid */
  customerId: string;
  connections: CustomerHubConnectionViewObject[];
};

export type CustomerLockViewObject = GuidEntityLockViewObject;

export interface CustomerPostalCodePriceRangeEntryViewObject {
  /** @format uuid */
  id: string;
  /** @format int32 */
  codeFrom: number;
  /** @format int32 */
  codeTo: number;
  /** @format uuid */
  postalCodePriceRangeId: string;
}

export interface CustomerPostalCodePriceRangeViewObject {
  /** @format uuid */
  id: string;
  /** @format double */
  baseCost: number;
  /** @format double */
  minCost: number;
  name: string;
  shipmentTypePriceRangeMetaData: CustomerPostalCodeShipmentTypePriceRangeMetaDataViewObject[];
  postalCodeRanges: CustomerPostalCodePriceRangeEntryViewObject[];
  shipmentTypePriceRanges: CustomerShipmentTypePriceRangeViewObject[];
}

export interface CustomerPostalCodeShipmentTypePriceRangeMetaDataViewObject {
  /** @format uuid */
  postalCodePriceRangeId: string;
  /** @format uuid */
  shipmentTypeId: string;
  fallbackStrategy: PostalCodeShipmentTypePriceRangeFallbackStrategy;
  /** @format double */
  baseCost: number;
  /** @format double */
  minCost: number;
}

export enum CustomerPriceCalculationMethod {
  None = "None",
  PostalCode = "PostalCode",
  Weight = "Weight",
  FullLoad = "FullLoad",
  Hour = "Hour",
}

export interface CustomerSettingsViewObject {
  allowedShipmentPayerTypes: ShipmentPayer[];
  sendConfirmationEmails: boolean;
  allowFastDelivery: boolean;
  showDeliveryPrices: boolean;
  trackTruckKM: boolean;
  requirePickupImageVerification: boolean;
  requireDeliveryImageVerification: boolean;
  /** @format int32 */
  hoursSinceCreationTaskCanBeEdited?: number | null;
  /** @format int32 */
  hoursSinceCreationTaskCanBeDeleted?: number | null;
  canUseDeliveryInformationTemplates: boolean;
}

export interface CustomerShipmentTypeCategoriesRequest {
  customerIds: string[];
}

export interface CustomerShipmentTypeCategoriesViewModel {
  shipmentTypeCategories: Record<string, CustomerShipmentTypeCategoryViewObject[]>;
}

export interface CustomerShipmentTypeCategoriesViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerShipmentTypeCategoriesViewModel;
}

export interface CustomerShipmentTypeCategoryViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  customerId: string;
  name: string;
  description: string;
  priceCalculationMethod: CustomerPriceCalculationMethod;
  /** @format double */
  baseCost: number;
  /** @format double */
  minCost: number;
  deletedSince?: string | null;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
  dimensionsDisplayOrder: DimensionsType[];
}

export interface CustomerShipmentTypeCategoryViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerShipmentTypeCategoryViewObject[];
}

export interface CustomerShipmentTypePriceRangeViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  shipmentTypeId: string;
  /** @format uuid */
  postalCodePriceRangeId: string;
  /** @format int32 */
  amountFrom: number;
  /** @format int32 */
  amountTo: number;
  /** @format double */
  weightFromKg: number;
  /** @format double */
  weightToKg: number;
  /** @format double */
  cubicMetreFrom: number;
  /** @format double */
  cubicMetreTo: number;
  /** @format double */
  loadMetreFrom: number;
  /** @format double */
  loadMetreTo: number;
  /** @format double */
  cost: number;
  /** @format double */
  costPerCubicMetre: number;
  /** @format double */
  costPerLoadMetre: number;
}

export interface CustomerShipmentTypeViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  shipmentTypeCategoryId: string;
  /** @format uuid */
  customerId: string;
  name: string;
  shortName?: string | null;
  description?: string | null;
  imageUrl: string;
  heightLimitCM: DimensionRestriction;
  widthLimitCM: DimensionRestriction;
  lengthLimitCM: DimensionRestriction;
  cubicMetreLimit: DimensionRestriction;
  loadMetreLimit: DimensionRestriction;
  weightLimitKG: DimensionRestriction;
  /** @format int32 */
  sort: number;
  /** @format double */
  baseCost: number;
  /** @format double */
  minCost: number;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
  deletedSince?: string | null;
  /** @format int32 */
  salesId: number;
  colorCodeIdentification?: string | null;
  aliases: string[];
}

export interface CustomerShipmentTypeViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerShipmentTypeViewObject[];
}

export interface CustomerShipmentTypeWeightPriceRangeViewObject {
  /** @format uuid */
  id: string;
  /** @format double */
  weightFromKg: number;
  /** @format double */
  weightToKg: number;
  /** @format double */
  cost: number;
}

export interface CustomerShipmentTypesRequest {
  customerIds: string[];
  excludeDeleted: boolean;
}

export interface CustomerShipmentTypesViewModel {
  shipmentTypes: Record<string, CustomerShipmentTypeViewObject[]>;
}

export interface CustomerShipmentTypesViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerShipmentTypesViewModel;
}

export interface CustomerShipmentWeightPriceRangeViewObject {
  /** @format uuid */
  id: string;
  /** @format double */
  weightFromKg: number;
  /** @format double */
  weightToKg: number;
  /** @format double */
  cost: number;
}

export type CustomerUserLoginHistoryViewObject = UserLoginHistoryViewObject & {
  /** @format uuid */
  businessEntityId: string;
  /** @format uuid */
  customerId: string;
};

export interface CustomerUserViewModel {
  user: CustomerUserViewObject;
}

export interface CustomerUserViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerUserViewModel;
}

export type CustomerUserViewObject = EntityViewObject & {
  name: string;
  email: string;
  lastName: string;
  fullName: string;
  phone?: string | null;
  whatsAppNumber?: string | null;
  lastLogin?: string | null;
  lastIp?: string | null;
  activeConnection?: CustomerHubUserViewObject | null;
};

export type CustomerViewObject = EntityViewObject & {
  /** @format uuid */
  businessEntityId: string;
  name: string;
  description?: string | null;
  logoUrl: string;
  isAvailableForShipping: boolean;
  /** @format int32 */
  userCount: number;
  /** @format int32 */
  orderCount: number;
  /** @format int32 */
  addressCount: number;
  /** @format int32 */
  activeUserCount: number;
  settings: CustomerSettingsViewObject;
  aliases: string[];
  contacts: CustomerContactViewObject[];
  address: AddressViewObject | ShipmentRequestDeliveryInformation;
  lock?: CustomerLockViewObject | null;
} & {
  marked: boolean;
  dragging: boolean;
  sort: number;
};

export interface CustomersViewModel {
  customers: CustomerViewObject[];
}

export interface CustomersViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomersViewModel;
}

export interface DashboardColorSettings {
  /** @minLength 1 */
  assignedTaskColor: string;
  /** @minLength 1 */
  completedTaskColor: string;
  /** @minLength 1 */
  dateAssignedTaskColor: string;
  /** @minLength 1 */
  directDeliveryTaskColor: string;
  /** @minLength 1 */
  inProgressTaskColor: string;
  /** @minLength 1 */
  inStorageTaskColor: string;
  /** @minLength 1 */
  awaitingStorageTaskColor: string;
  /** @minLength 1 */
  invoicedTaskColor: string;
  /** @minLength 1 */
  nextDayDeliveryTaskColor: string;
  /** @minLength 1 */
  paidTaskColor: string;
  /** @minLength 1 */
  unassignedTaskColor: string;
  /** @minLength 1 */
  taskOriginManualColor: string;
  /** @minLength 1 */
  taskOriginScheduleColor: string;
  /** @minLength 1 */
  taskOriginBookingColor: string;
  /** @minLength 1 */
  taskOriginMailColor: string;
}

export enum DashboardRouteAreaAccess {
  Dashboard = "Dashboard",
  LiveView = "LiveView",
  Resources = "Resources",
  Storages = "Storages",
  Tasks = "Tasks",
  Customers = "Customers",
  Employees = "Employees",
  Dictionary = "Dictionary",
  Statistics = "Statistics",
  Administration = "Administration",
  Auth = "Auth",
  Notifications = "Notifications",
}

export type DashboardViewEntryLockViewObject = GuidDateTimeEntityLockViewObject & {
  /** @format uuid */
  entryId: string;
  date: string;
};

export type DashboardViewLockViewObject = GuidEntityLockViewObject;

export interface DeleteChatRequest {
  /** @format uuid */
  chatId: string;
}

export type DeliveryDateTimeOfDay = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  scope: DeliveryDateTimeOfDayEvaluationScope;
  time: TimeOfDay;
  TypeDiscriminator: string;
};

export enum DeliveryDateTimeOfDayEvaluationScope {
  LessThan = "LessThan",
  Equals = "Equals",
  GreaterThan = "GreaterThan",
}

export interface DeliveryDeviationTypeViewModel {
  deliveryDeviations: DeliveryDeviationTypeViewObject[];
}

export interface DeliveryDeviationTypeViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DeliveryDeviationTypeViewModel;
}

export interface DeliveryDeviationTypeViewObject {
  /** @format uuid */
  id: string;
  name: string;
  description: DeviationFieldType;
  images: DeviationFieldType;
  amount: DeviationFieldType;
  created: string;
  /** @format uuid */
  createdBy?: string | null;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
  /** @format int32 */
  taskGridColumnId?: number | null;
  enabled: boolean;
}

export interface DeliveryDeviationViewObject {
  /** @format uuid */
  deviationTypeId: string;
  description?: string | null;
  imageUrls?: string[] | null;
  /** @format double */
  amount?: number | null;
}

export interface DeliveryFeeValue {
  TypeDiscriminator: string;
}

export interface DeliveryFeeViewObject {
  /** @format uuid */
  id: string;
  name: string;
  description: string;
  /** @format uuid */
  modifiedBy: string;
  modified: string;
  /** @format uuid */
  createdBy: string;
  created: string;
  /** @format int32 */
  order: number;
  active: boolean;
  validations: (
    | CustomerAddressEquals
    | DeliveryDateTimeOfDay
    | DeliveryItemsCount
    | DeliveryItemsTypeAndCount
    | DeliveryTypeEquals
    | DeliveryValidationAndCondition
    | DeliveryValidationDeliveryStatus
    | DeliveryValidationDeliveryStepStatus
    | DeliveryValidationDeliveryStepType
    | DeliveryValidationHasDeliveryDate
    | DeliveryValidationHasDescription
    | DeliveryValidationHasPickupDate
    | DeliveryValidationOrCondition
    | DeliveryValidationStepHasDescription
    | DeliveryValidationStorageLocation
    | ShipmentPayerEquals
  )[];
  value: AccumulatedFeePercentageValue | PostponeDeliveryDateDeliveryFeeValue | ValueDeliveryFeeValue;
}

export interface DeliveryItemViewObject {
  /** @format uuid */
  id: string;
  name: string;
  /** @format uuid */
  customerId: string;
  /** @format uuid */
  deliveryInformationId: string;
  /** @format uuid */
  shipmentTypeId: string;
  /** @format int32 */
  amount: number;
  /** @format double */
  cost?: number | null;
  /** @format double */
  totalCost?: number | null;
  /** @format double */
  weightKG: number;
  /** @format double */
  heightCM?: number | null;
  /** @format double */
  lengthCM?: number | null;
  /** @format double */
  widthCM?: number | null;
  /** @format double */
  cubicMetre?: number | null;
  /** @format double */
  loadMetre?: number | null;
  /** @format double */
  totalWeightKG: number;
  /** @format double */
  totalCubicMetre?: number | null;
  /** @format double */
  totalLoadMetre?: number | null;
  description?: string | null;
  reference?: string | null;
  customCost: boolean;
}

export type DeliveryItemsCount = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  scope: DeliveryItemsCountEvaluationScope;
  /** @format int32 */
  count: number;
  method: DeliveryItemsCountEvaluationMethod;
  TypeDiscriminator: string;
};

export enum DeliveryItemsCountEvaluationMethod {
  Sum = "Sum",
  Lines = "Lines",
}

export enum DeliveryItemsCountEvaluationScope {
  LessThan = "LessThan",
  Equals = "Equals",
  GreaterThan = "GreaterThan",
}

export type DeliveryItemsTypeAndCount = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  /** @format int32 */
  count: number;
  scope: DeliveryItemsTypeAndCountEvaluationScope;
  /** @format uuid */
  shipmentTypeId: string;
  TypeDiscriminator: string;
};

export enum DeliveryItemsTypeAndCountEvaluationScope {
  LessThan = "LessThan",
  Equals = "Equals",
  GreaterThan = "GreaterThan",
}

export interface DeliveryServerContextViewObject {
  apiBase: string;
  businessEntity: BusinessEntityViewObject;
}

export interface DeliveryServerContextViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DeliveryServerContextViewObject;
}

export interface DeliverySettings {
  /** @format int32 */
  maxDaysForward: number;
  forceGeoLocationTracking: boolean;
  breakReasons: string[];
  breakReasonsIsRequired: boolean;
  alwaysShowBothAddresses: boolean;
  showDeliveryTime: boolean;
  showPickupTime: boolean;
  showDescription: boolean;
  deliveryTypesToShowAsOne: DeliveryType[];
  trackTaskStart: boolean;
  trackTruckKM: boolean;
  trackNumberOfLoads: boolean;
  /** @format int32 */
  taskCompletionCancellationTime: number;
  showDownloadScheduleButton: boolean;
  allowOverwritingStorageLocation: boolean;
  allowEndingDayWithoutCompletingAllTasks: boolean;
  /** @format int32 */
  driverLogDurationHoursWarning: number;
}

export interface DeliveryStatistics {
  /** @format double */
  timeBetweenStepsSeconds?: number | null;
  /** @format double */
  totalDurationSeconds?: number | null;
  /** @format double */
  co2Emissions: number;
}

export enum DeliveryStatus {
  Unassigned = "Unassigned",
  DateAssigned = "DateAssigned",
  Assigned = "Assigned",
  AwaitingStorage = "AwaitingStorage",
  InStorage = "InStorage",
  InProgress = "InProgress",
  Complete = "Complete",
  Invoiced = "Invoiced",
}

export type DeliveryStepDeliveryViewObject = DeliveryStepViewObject & {
  /** @format uuid */
  storageId?: string | null;
  /** @format uuid */
  storedByUserId?: string | null;
  storedSince?: string | null;
  isInStorage: boolean;
  isAwaitingStorage: boolean;
};

export type DeliveryStepPickupViewObject = DeliveryStepViewObject & {
  storageLocation: StorageLocation;
  /** @format uuid */
  selectedStorageId?: string | null;
  deliveryDeviation?: DeliveryDeviationViewObject | null;
};

export enum DeliveryStepStatus {
  Unassigned = "Unassigned",
  DateAssigned = "DateAssigned",
  Assigned = "Assigned",
  AwaitingStorage = "AwaitingStorage",
  InStorage = "InStorage",
  InProgress = "InProgress",
  Complete = "Complete",
}

export enum DeliveryStepType {
  Pickup = "Pickup",
  Delivery = "Delivery",
}

export interface DeliveryStepViewObject {
  /** @format uuid */
  deliveryTaskId: string;
  title: string;
  description?: string | null;
  status: DeliveryStepStatus;
  requestedHandleDate?: string | null;
  scheduleDate?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  expectedDuration: DurationViewObject;
  carrierDuration: DurationViewObject;
  startLocation?: GeoLocation | null;
  endLocation?: GeoLocation | null;
  /** @format double */
  startKM?: number | null;
  /** @format double */
  endKM?: number | null;
  imageUrl?: string | null;
  /** @format uuid */
  startedBy?: string | null;
  /** @format uuid */
  completedBy?: string | null;
  isInProgress: boolean;
  isCompleted: boolean;
  /** @format int32 */
  order: number;
  notifyDriver: boolean;
  visibleForDriver: boolean;
  /** @format uuid */
  driverLogId?: string | null;
  dashboardEntries?: Record<string, string | null>;
  scheduleEntry?: DeliveryTaskScheduleEntryViewObject | null;
  information?: DeliveryTaskStepInformationViewObject | null;
  deliveryStepType: DeliveryStepType;
}

export type DeliveryTaskEntityLockViewObject = GuidEntityLockViewObject;

export interface DeliveryTaskMarkStepNotified {
  /** @format uuid */
  taskId: string;
  pickup: boolean;
  delivery: boolean;
}

export enum DeliveryTaskOrigin {
  Manual = "Manual",
  Schedule = "Schedule",
  Booking = "Booking",
  Mail = "Mail",
  Import = "Import",
}

export interface DeliveryTaskScheduleEntryViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  resourceId?: string | null;
  /** @format uuid */
  userId?: string | null;
}

export interface DeliveryTaskStepInformationViewObject {
  images?: string[] | null;
  information?: string | null;
}

export interface DeliveryTaskViewObject {
  /** @format uuid */
  id: string;
  title: string;
  description?: string | null;
  information?: string | null;
  loadType?: string | null;
  origin: DeliveryTaskOrigin;
  status: DeliveryStatus;
  /** @format uuid */
  businessEntityId: string;
  /** @format uuid */
  customerId: string;
  deliveryInformation: CustomerDeliveryInformationViewObject;
  pickup: DeliveryStepPickupViewObject;
  delivery: DeliveryStepDeliveryViewObject;
  created: string;
  /** @format uuid */
  createdBy: string;
  modified?: string | null;
  startDate?: string | null;
  completionDate?: string | null;
  /** @format uuid */
  invoiceId?: string | null;
  createdFromMailId?: string | null;
  /** @format uuid */
  createdFromScheduledTaskId?: string | null;
  locked: boolean;
  isCompleted: boolean;
  isInvoiced: boolean;
  mergeTaskIntoSinglePickupDelivery: boolean;
  deliveryStatistics: DeliveryStatistics;
  lock?: DeliveryTaskEntityLockViewObject | null;

  marked: boolean;
  hovering: boolean;
  dragging: boolean;
  loading: boolean;
  step?: DeliveryStepViewObject;
}

export enum DeliveryType {
  Direct = "Direct",
  NextDay = "NextDay",
}

export type DeliveryTypeEquals = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  deliveryType: DeliveryType;
  TypeDiscriminator: string;
};

export interface DeliveryValidation {
  TypeDiscriminator: string;
  negated: boolean;
}

export type DeliveryValidationAndCondition = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  validations: (
    | CustomerAddressEquals
    | DeliveryDateTimeOfDay
    | DeliveryItemsCount
    | DeliveryItemsTypeAndCount
    | DeliveryTypeEquals
    | DeliveryValidationAndCondition
    | DeliveryValidationDeliveryStatus
    | DeliveryValidationDeliveryStepStatus
    | DeliveryValidationDeliveryStepType
    | DeliveryValidationHasDeliveryDate
    | DeliveryValidationHasDescription
    | DeliveryValidationHasPickupDate
    | DeliveryValidationOrCondition
    | DeliveryValidationStepHasDescription
    | DeliveryValidationStorageLocation
    | ShipmentPayerEquals
  )[];
  TypeDiscriminator: string;
};

export type DeliveryValidationDeliveryStatus = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  status: DeliveryStatus;
  TypeDiscriminator: string;
};

export type DeliveryValidationDeliveryStepStatus = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  status: DeliveryStepStatus;
  TypeDiscriminator: string;
};

export type DeliveryValidationDeliveryStepType = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  deliveryStepType: DeliveryStepType;
  TypeDiscriminator: string;
};

export type DeliveryValidationHasDeliveryDate = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  TypeDiscriminator: string;
};

export type DeliveryValidationHasDescription = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  TypeDiscriminator: string;
};

export type DeliveryValidationHasPickupDate = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  TypeDiscriminator: string;
};

export type DeliveryValidationOrCondition = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  validations: (
    | CustomerAddressEquals
    | DeliveryDateTimeOfDay
    | DeliveryItemsCount
    | DeliveryItemsTypeAndCount
    | DeliveryTypeEquals
    | DeliveryValidationAndCondition
    | DeliveryValidationDeliveryStatus
    | DeliveryValidationDeliveryStepStatus
    | DeliveryValidationDeliveryStepType
    | DeliveryValidationHasDeliveryDate
    | DeliveryValidationHasDescription
    | DeliveryValidationHasPickupDate
    | DeliveryValidationOrCondition
    | DeliveryValidationStepHasDescription
    | DeliveryValidationStorageLocation
    | ShipmentPayerEquals
  )[];
  TypeDiscriminator: string;
};

export type DeliveryValidationStepHasDescription = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  deliveryStepType: DeliveryStepType;
  TypeDiscriminator: string;
};

export type DeliveryValidationStorageLocation = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  storageLocation: StorageLocation;
  TypeDiscriminator: string;
};

export enum DeviationFieldType {
  Hidden = "Hidden",
  Allowed = "Allowed",
  Required = "Required",
}

export enum DictionaryLabelContentType {
  Text = "Text",
  HTML = "HTML",
}

export interface DictionaryLabelViewObject {
  key: string;
  text: string;
  area?: string | null;
  languageCode: string;
  lastModified?: string | null;
  /** @format uuid */
  lastModifiedBy?: string | null;
  contentType: DictionaryLabelContentType;
}

export interface DictionaryLabelsViewModel {
  labels: DictionaryLabelViewObject[];
}

export interface DictionaryLabelsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DictionaryLabelsViewModel;
}

export interface DictionaryLanguageViewObject {
  languageCode: string;
  dictionaryKey: string;
  isFallback: boolean;
}

export interface DictionaryLanguagesViewModel {
  languages: DictionaryLanguageViewObject[];
}

export interface DictionaryLanguagesViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DictionaryLanguagesViewModel;
}

export interface DimensionRestriction {
  /** @format double */
  baseValue?: number | null;
  /** @format double */
  minValue?: number | null;
  /** @format double */
  maxValue?: number | null;
  fieldType: DimensionRestrictionFieldType;
}

export enum DimensionRestrictionFieldType {
  Hidden = "Hidden",
  Allowed = "Allowed",
  Required = "Required",
}

export enum DimensionsType {
  Height = "Height",
  Width = "Width",
  Length = "Length",
}

export interface DirectionsRequest {
  startLocation?: LatLng | null;
  endLocation?: LatLng | null;
  addresses: DirectionsRequestTaskAddressInfo[];
  /** @format double */
  maximumSpeedKM: number;
}

export interface DirectionsRequestTaskAddressInfo {
  /** @format uuid */
  taskId: string;
  deliveryType: DeliveryStepType;
  address: AddressViewObject | ShipmentRequestDeliveryInformation;
}

export interface DistanceSummary {
  /** @format double */
  durationInMinutes: number;
  /** @format double */
  distanceInMeters: number;
}

export interface DistanceSummaryClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DistanceSummary;
}

export interface DriverLogPreStartCheckEntry {
  /** @format uuid */
  preStartCheckId: string;
  imageUrl?: string | null;
}

export interface DriverLogRequest {
  location?: GeoLocation | null;
}

export interface DurationViewObject {
  startDateTime?: string | null;
  endDateTime?: string | null;
  /** @format double */
  totalDurationSeconds?: number | null;
}

export interface EmployeeViewObject {
  /** @format uuid */
  id: string;
  created: string;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
  deletedSince?: string | null;
  name: string;
  email: string;
  phone?: string | null;
  whatsAppNumber?: string | null;
  lastName: string;
  fullName: string;
  blocked: boolean;
  isStaff: boolean;
  lastLogin?: string | null;
  lastIp?: string | null;
  profileImageUrl?: string | null;
  allowableIps: string[];
  /** @format int32 */
  identifier: number;
  hideInLiveView: boolean;
  role: BusinessEntityUserRole;
  activeConnections?: BusinessEntityHubConnectionViewObject[] | null;
}

export interface EntityViewObject {
  /** @format uuid */
  id: string;
  created: string;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
  deletedSince?: string | null;
}

export interface GPSDirections {
  routes: Route[];
  summary: Summary;
}

export interface GPSDirectionsClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: GPSDirections;
}

export interface GeoLocation {
  /** @format double */
  accuracy: number;
  /** @format double */
  latitude: number;
  /** @format double */
  longitude: number;
  /** @format double */
  altitude?: number | null;
  /** @format double */
  altitudeAccuracy?: number | null;
  /** @format double */
  heading?: number | null;
  /** @format double */
  speed?: number | null;
}

export interface GeoLocationAddress {
  /** @format double */
  latitude: number;
  /** @format double */
  longitude: number;
  street: string;
  city: string;
  postalCode: string;
  country: string;
  /** @format date-time */
  created: string;
  source: string;
  formatted: string;
  isExpired: boolean;
}

export interface GeoLocationAddressClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: GeoLocationAddress;
}

export interface GroupChatMemberViewObject {
  /** @format uuid */
  userId: string;
  joinedDate: string;
}

export interface GroupChatMessageViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  chatId: string;
  /** @format uuid */
  senderUserId: string;
  message?: string | null;
  imageUrl?: string | null;
  created: string;
  modified?: string | null;
  readBy: string[];
}

export interface GroupChatMessageViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: GroupChatMessageViewObject;
}

export interface GroupChatMessagesViewModel {
  /** @format int32 */
  total: number;
  messages: GroupChatMessageViewObject[];
}

export interface GroupChatMessagesViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: GroupChatMessagesViewModel;
}

export interface GroupChatViewModel {
  groupChats: GroupChatViewObject[];
}

export interface GroupChatViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: GroupChatViewModel;
}

export interface GroupChatViewObject {
  /** @format uuid */
  id: string;
  title?: string | null;
  created: string;
  updated: string;
  /** @format uuid */
  createdBy?: string | null;
  members: GroupChatMemberViewObject[];
  messages: GroupChatMessageViewObject[];
}

export interface GroupChatViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: GroupChatViewObject;
}

export interface GuidDateTimeEntityLockViewObject {
  /** @format uuid */
  userId: string;
  userName: string;
  lockAcquired: string;
  lockReason: string;
  connectionId: string;
}

export interface GuidEntityLockViewObject {
  /** @format uuid */
  entityId: string;
  /** @format uuid */
  userId: string;
  userName: string;
  lockAcquired: string;
  lockReason: string;
  connectionId: string;
}

export type HttpValidationProblemDetails = ProblemDetails & {
  errors: Record<string, string[]>;
  [key: string]: any;
};

export interface HubConnectionViewObject {
  /** @format uuid */
  userId: string;
  connectionId: string;
  ipAddress?: string | null;
  channel?: string | null;
  appVersionId?: string | null;
  connected: string;
  tokenVersion: string;
  activity: string;
  /** @format uuid */
  isTypingInChat?: string | null;
  lastActivity: string;
}

export interface ImageUploadResponse {
  url: string;
  name: string;
}

export interface ImageUploadResponseClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ImageUploadResponse;
}

export type InvoiceLockViewObject = GuidEntityLockViewObject;

export interface IpInformationViewObject {
  ip: string;
  zip: string;
  city: string;
  country: string;
  countryCode: string;
}

export interface IpInformationViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: IpInformationViewObject;
}

export interface LatLng {
  /** @format double */
  lat: number;
  /** @format double */
  lng: number;
}

export interface LoginRequest {
  /** @minLength 1 */
  domain: string;
  /** @minLength 1 */
  email: string;
  /** @minLength 8 */
  password: string;
}

export interface LoginResponse {
  token: string;
  user: UserViewObject;
}

export interface LoginResponseClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: LoginResponse | RefreshSessionResponse;
}

export interface MarkChatAsReadByUserRequest {
  /** @format uuid */
  chatId: string;
}

export interface MarkLabelAsMissingRequest {
  /** @minLength 1 */
  key: string;
  /** @minLength 1 */
  language: string;
  /** @minLength 1 */
  location: string;
}

export interface MarkTaskStepsAsNotifiedRequest {
  taskStepMarker: DeliveryTaskMarkStepNotified;
}

export enum NotificationAlignment {
  Left = "Left",
  Right = "Right",
  Center = "Center",
}

export enum NotificationPosition {
  Top = "Top",
  Bottom = "Bottom",
  Center = "Center",
}

export enum NotificationTarget {
  Business = "Business",
  Shipping = "Shipping",
  Delivery = "Delivery",
}

export enum NotificationType {
  Success = "Success",
  Info = "Info",
  Warning = "Warning",
  Error = "Error",
}

export interface NotificationViewModel {
  notifications: NotificationViewObject[];
}

export interface NotificationViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: NotificationViewModel;
}

export type NotificationViewObject = EntityViewObject & {
  title: string;
  message?: string | null;
  activeFrom?: string | null;
  activeTo?: string | null;
  target: NotificationTarget;
  type: NotificationType;
  alignment: NotificationAlignment;
  position: NotificationPosition;
  /** @format int32 */
  order: number;
  fullWidth: boolean;
  enabled: boolean;
};

export interface OpeningHoursViewObject {
  /** @format double */
  from: number;
  /** @format double */
  to: number;
}

export type PagedTasksViewModel = TasksViewModel & {
  /** @format int32 */
  total: number;
};

export interface PostalCodeInformation {
  /** @format int32 */
  number: number;
  name: string;
}

export interface PostalCodeSearchResponse {
  postalCodeInformations: PostalCodeInformation[];
}

export interface PostalCodeSearchResponseClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: PostalCodeSearchResponse;
}

export enum PostalCodeShipmentTypePriceRangeFallbackStrategy {
  None = "None",
  LowestAmount = "LowestAmount",
  HighestAmount = "HighestAmount",
  LowestWeight = "LowestWeight",
  HighestWeight = "HighestWeight",
  LowestCubicMetre = "LowestCubicMetre",
  HighestCubicMetre = "HighestCubicMetre",
  LowestPrice = "LowestPrice",
  HighestPrice = "HighestPrice",
  LowestLoadMetre = "LowestLoadMetre",
  HighestLoadMetre = "HighestLoadMetre",
}

export type PostponeDeliveryDateDeliveryFeeValue = UtilRequiredKeys<DeliveryFeeValue, "TypeDiscriminator"> & {
  /** @format double */
  baseCost: number;
  shipmentTypeCosts: Record<string, number>;
  TypeDiscriminator: string;
};

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export type RefreshSessionResponse = LoginResponse;

export interface RefreshSessionResponseClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: RefreshSessionResponse;
}

export interface RemoveUserFromChatRequest {
  /** @format uuid */
  chatId: string;
  userIds: string[];
}

export interface RenameChatRequest {
  /** @format uuid */
  chatId: string;
  title?: string | null;
}

export interface RentalSettings {
  enabled: boolean;
}

export interface RequestPasswordResetRequest {
  /** @minLength 1 */
  domain: string;
  /** @minLength 1 */
  email: string;
}

export interface ResetPasswordRequest {
  /** @minLength 1 */
  domain: string;
  /** @format uuid */
  resetPasswordToken: string;
  /** @minLength 1 */
  password: string;
}

export interface ResourceCategoriesViewModel {
  categories: ResourceCategoryViewObject[];
}

export interface ResourceCategoriesViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ResourceCategoriesViewModel;
}

export type ResourceCategoryEntityLockViewObject = GuidEntityLockViewObject;

export type ResourceCategoryViewObject = EntityViewObject & {
  name: string;
  description?: string | null;
  image?: string | null;
  /** @format double */
  maxLoadKg: number;
  /** @format int32 */
  maxEuroPallets: number;
  /** @format double */
  heightCM: number;
  /** @format double */
  widthCM: number;
  /** @format double */
  lengthCM: number;
  /** @format double */
  loadMeter: number;
  /** @format double */
  maximumSpeedKM: number;
  /** @format double */
  co2EmissionsPerKm: number;
  lock?: ResourceCategoryEntityLockViewObject | null;
} & {
  marked: boolean;
  dragging: boolean;
  sort: number;
};

export type ResourceEntityLockViewObject = GuidEntityLockViewObject;

export type ResourceViewObject = EntityViewObject & {
  title: string;
  description?: string | null;
  /** @format int32 */
  identifier: number;
  /** @format uuid */
  categoryId: string;
  startAddress?: AddressViewObject | ShipmentRequestDeliveryInformation | null;
  lock?: ResourceEntityLockViewObject | null;
} & {
  marked: boolean;
  dragging: boolean;
  sort: number;
};

export interface ResourcesViewModel {
  resources: ResourceViewObject[];
}

export interface ResourcesViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ResourcesViewModel;
}

export interface Route {
  summary: Summary;
  geometry?: string | null;
  addressGeoLocations: TaskAddressInfo[];
}

export interface ScheduleEntryDashboardViewEntryLocationViewObject {
  /** @format uuid */
  dashboardViewId: string;
  /** @format uuid */
  dashboardEntryId: string;
}

export enum ScheduleEntryType {
  Schedule = "Schedule",
  Service = "Service",
  Vacant = "Vacant",
  Vacation = "Vacation",
  Sick = "Sick",
  Other = "Other",
}

export interface ScheduleEntryViewObject {
  /** @format uuid */
  id: string;
  type: ScheduleEntryType;
  /** @format uuid */
  resourceId?: string | null;
  /** @format uuid */
  userId?: string | null;
  dashboardEntries?: ScheduleEntryDashboardViewEntryLocationViewObject[] | null;
  created: string;
  /** @format uuid */
  createdBy: string;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
  startDate: string;
  endDate: string;
  description?: string | null;
  color?: string | null;
  isActive: boolean;
  userInformation?: string | null;
  scheduleInformation?: string | null;
  resourceInformation?: string | null;
  locks: ScheduleLockViewObject[];
}

export type ScheduleLockViewObject = GuidDateTimeEntityLockViewObject & {
  /** @format uuid */
  scheduleId: string;
  scheduleDate: string;
};

export type ScheduledTaskLockViewObject = GuidEntityLockViewObject;

export interface SchedulesEntryViewModel {
  entries: ScheduleEntryViewObject[];
}

export interface SchedulesEntryViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: SchedulesEntryViewModel;
}

export interface SearchAddressResponse {
  address: string;
  street: string;
  city: string;
  /** @format int32 */
  postalCode: number;
}

export interface SearchAddressesResponse {
  addresses: SearchAddressResponse[];
}

export interface SearchAddressesResponseClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: SearchAddressesResponse;
}

export interface SendChatMessageRequest {
  /** @format uuid */
  chatId: string;
  message?: string | null;
  imageUrl?: string | null;
}

export enum ServiceFeeCalculationEntry {
  BeforeDeliveryFees = "BeforeDeliveryFees",
  AfterDeliveryPrices = "AfterDeliveryPrices",
}

export enum ShipmentPayer {
  Sender = "Sender",
  Receiver = "Receiver",
  Other = "Other",
}

export type ShipmentPayerEquals = UtilRequiredKeys<DeliveryValidation, "TypeDiscriminator"> & {
  shipmentPayer: ShipmentPayer;
  TypeDiscriminator: string;
};

export interface ShipmentRequest {
  /** @format uuid */
  id?: string | null;
  /** @format uuid */
  customerId: string;
  senderAddress: ShipmentRequestDeliveryInformation;
  receiverAddress: ShipmentRequestDeliveryInformation;
  billingAddress?: ShipmentRequestDeliveryInformation | null;
  description?: string | null;
  pickupDescription?: string | null;
  deliveryDescription?: string | null;
  /** @format date-time */
  pickupDate?: string | null;
  /** @format date-time */
  deliveryDate?: string | null;
  shipmentPayer: ShipmentPayer;
  deliveryType: DeliveryType;
  serviceFees: CustomerDeliveryInformationServiceFeeViewObject[];
  deliveryItems: ShipmentRequestDeliveryItem[];
  sendMailsToCustomer: boolean;
  sendMailsToBusinessEntity: boolean;
  createdFromMailId?: string | null;
  mergeTaskIntoSinglePickupDelivery?: boolean | null;
  /** @format double */
  discountPercentage?: number | null;
  recalculateDeliveryPrice?: boolean | null;
  saveToDatabase?: boolean | null;
  duration?: ShipmentRequestDuration | null;
  /** @format double */
  hourPrice?: number | null;
  /** @format double */
  fullLoadPrice?: number | null;
}

export type ShipmentRequestDeliveryInformation = AddressViewObject & {
  /** @format int32 */
  id?: number | null;
  isCustomerAddress: boolean;
};

export interface ShipmentRequestDeliveryItem {
  /** @format uuid */
  id: string;
  /** @format uuid */
  shipmentTypeId: string;
  /** @format int32 */
  amount: number;
  /** @format double */
  cost?: number | null;
  /** @format double */
  weightKG?: number | null;
  /** @format double */
  heightCM?: number | null;
  /** @format double */
  lengthCM?: number | null;
  /** @format double */
  widthCM?: number | null;
  /** @format double */
  cubicMetre?: number | null;
  /** @format double */
  loadMetre?: number | null;
  description?: string | null;
  reference?: string | null;
  customCost: boolean;
}

export interface ShipmentRequestDuration {
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
}

export interface ShipmentResponse {
  task?: DeliveryTaskViewObject | null;
  deliveryInformation: CustomerDeliveryInformationViewObject;
}

export interface ShippingColorSettings {
  /** @minLength 1 */
  buttonColorFrom: string;
  /** @minLength 1 */
  buttonColorTo: string;
}

export interface ShippingSettings {
  deliveryItemsShowCost: boolean;
  shipmentPayerUserVerificationDialog: boolean;
  showDownloadPdfOnOrderSuccessPage: boolean;
  allowBookingWeekends: boolean;
  showDurationMatrix: boolean;
  showTotalCubicMetre: boolean;
  showTotalLoadMetre: boolean;
  allowUpdatingAddressesAsCustomerAddress: boolean;
  /** @format int32 */
  hoursSinceCreationTaskCanBeEdited: number;
  /** @format int32 */
  hoursSinceCreationTaskCanBeDeleted: number;
}

export type StartDriverLogBreakRequest = DriverLogRequest & {
  /** @maxLength 1000 */
  description?: string | null;
};

export type StartDriverLogRequest = DriverLogRequest & {
  /** @maxLength 1000 */
  description?: string | null;
  preStartCheckEntries?: DriverLogPreStartCheckEntry[] | null;
};

export enum StorageLocation {
  Storage = "Storage",
  KeepOnTruck = "KeepOnTruck",
}

export interface Summary {
  /** @format double */
  distanceInMeters: number;
  /** @format double */
  durationInSeconds: number;
}

export interface TaskAddressInfo {
  /** @format uuid */
  taskId: string;
  deliveryType: DeliveryStepType;
  geoLocationAddress: GeoLocationAddress;
}

export interface TaskImportConfiguration {
  customer: TaskImportConfigurationCustomer;
  shipmentType: TaskImportConfigurationShipmentType;
  properties: Record<string, TaskImportConfigurationProperty>;
}

export interface TaskImportConfigurationCustomer {
  id?: string | null;
  header?: string | null;
}

export interface TaskImportConfigurationProperty {
  manual?: string | null;
  header?: string | null;
  customer?: string | null;
}

export interface TaskImportConfigurationShipmentType {
  shipmentTypeName?: string | null;
  header?: string | null;
}

export interface TaskProgressRequest {
  /** @format uuid */
  taskId: string;
  type: DeliveryStepType;
  location?: GeoLocation | null;
  /** @format double */
  truckKM?: number | null;
  imageUrl?: string | null;
}

export interface TaskStatisticsDateEntryViewObject {
  date: string;
  /** @format int32 */
  count: number;
  allTasksComplete: boolean;
}

export interface TaskStatisticsDateEntryViewObjectArrayClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: TaskStatisticsDateEntryViewObject[];
}

export interface TaskViewModel {
  task: DeliveryTaskViewObject;
}

export interface TaskViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: TaskViewModel;
}

export interface TasksByIdRequest {
  taskIds: string[];
}

export interface TasksForDriverLogRequest {
  driverLogIds: string[];
}

export interface TasksViewModel {
  tasks: DeliveryTaskViewObject[];
}

export interface TasksViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: TasksViewModel | PagedTasksViewModel;
}

export interface TimeOfDay {
  /** @format int32 */
  hour: number;
  /** @format int32 */
  minute: number;
  /** @format int32 */
  second: number;
}

export interface TrackLocationRequest {
  /** @format double */
  accuracy?: number | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  /** @format double */
  altitude?: number | null;
  /** @format double */
  altitudeAccuracy?: number | null;
  /** @format double */
  heading?: number | null;
  /** @format double */
  speed?: number | null;
  /** @format uuid */
  scheduleEntryId?: string | null;
  /** @format uuid */
  driverLogId?: string | null;
}

export interface UserLoginHistoryViewObject {
  /** @format uuid */
  userId: string;
  ip: string;
  date: string;
  logoutDate?: string | null;
  /** @format int32 */
  sessionDuration?: number | null;
  channel?: string | null;
  appVersionId?: string | null;
}

export type UserViewObject = EmployeeViewObject & {
  businessEntity: BusinessEntityViewObject;
};

export interface UsersRequest {
  userIds?: string[] | null;
}

export interface ValidationMessage {
  message: string;
  args?: any[] | null;
}

export type ValueDeliveryFeeValue = UtilRequiredKeys<DeliveryFeeValue, "TypeDiscriminator"> & {
  /** @format double */
  value: number;
  TypeDiscriminator: string;
};

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Imendo.Delivery API
 * @version v1
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Auth
     * @name AuthLoginCreate
     * @request POST:/api/Auth/Login
     * @secure
     */
    authLoginCreate: (data: LoginRequest, params: RequestParams = {}) =>
      this.request<LoginResponseClientResponse, ProblemDetails | HttpValidationProblemDetails>({
        path: `/api/Auth/Login`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthLogoutCreate
     * @request POST:/api/Auth/Logout
     * @secure
     */
    authLogoutCreate: (params: RequestParams = {}) =>
      this.request<BooleanClientResponse, ProblemDetails | HttpValidationProblemDetails>({
        path: `/api/Auth/Logout`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthRefreshSessionList
     * @request GET:/api/Auth/RefreshSession
     * @secure
     */
    authRefreshSessionList: (params: RequestParams = {}) =>
      this.request<RefreshSessionResponseClientResponse, ProblemDetails | HttpValidationProblemDetails>({
        path: `/api/Auth/RefreshSession`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthChangePasswordCreate
     * @request POST:/api/Auth/ChangePassword
     * @secure
     */
    authChangePasswordCreate: (data: ChangePasswordRequest, params: RequestParams = {}) =>
      this.request<RefreshSessionResponseClientResponse, any>({
        path: `/api/Auth/ChangePassword`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthRequestPasswordResetCreate
     * @request POST:/api/Auth/RequestPasswordReset
     * @secure
     */
    authRequestPasswordResetCreate: (data: RequestPasswordResetRequest, params: RequestParams = {}) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Auth/RequestPasswordReset`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthResetPasswordCreate
     * @request POST:/api/Auth/ResetPassword
     * @secure
     */
    authResetPasswordCreate: (data: ResetPasswordRequest, params: RequestParams = {}) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Auth/ResetPassword`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityGetLocationTrackingList
     * @request GET:/api/BusinessEntity/GetLocationTracking
     * @secure
     */
    businessEntityGetLocationTrackingList: (
      query?: {
        /** @format uuid */
        driverLogId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityUserLocationTrackingLogViewModelClientResponse, any>({
        path: `/api/BusinessEntity/GetLocationTracking`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityIsDomainValidList
     * @request GET:/api/BusinessEntity/IsDomainValid
     * @secure
     */
    businessEntityIsDomainValidList: (
      query: {
        domain: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/BusinessEntity/IsDomainValid`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityGetServerContextList
     * @request GET:/api/BusinessEntity/GetServerContext
     * @secure
     */
    businessEntityGetServerContextList: (params: RequestParams = {}) =>
      this.request<DeliveryServerContextViewObjectClientResponse, any>({
        path: `/api/BusinessEntity/GetServerContext`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityGetDeliveryDeviationTypesList
     * @request GET:/api/BusinessEntity/GetDeliveryDeviationTypes
     * @secure
     */
    businessEntityGetDeliveryDeviationTypesList: (params: RequestParams = {}) =>
      this.request<DeliveryDeviationTypeViewModelClientResponse, any>({
        path: `/api/BusinessEntity/GetDeliveryDeviationTypes`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessEntity
     * @name BusinessEntityGetUserDriverLogPreStartChecksList
     * @request GET:/api/BusinessEntity/GetUserDriverLogPreStartChecks
     * @secure
     */
    businessEntityGetUserDriverLogPreStartChecksList: (
      query: {
        /** @format uuid */
        resourceId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityUserDriverLogPreStartCheckViewModelClientResponse, any>({
        path: `/api/BusinessEntity/GetUserDriverLogPreStartChecks`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationSendChatMessageCreate
     * @request POST:/api/Communication/SendChatMessage
     * @secure
     */
    communicationSendChatMessageCreate: (data: SendChatMessageRequest, params: RequestParams = {}) =>
      this.request<GroupChatMessageViewObjectClientResponse, any>({
        path: `/api/Communication/SendChatMessage`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationMarkChatAsReadByUserCreate
     * @request POST:/api/Communication/MarkChatAsReadByUser
     * @secure
     */
    communicationMarkChatAsReadByUserCreate: (data: MarkChatAsReadByUserRequest, params: RequestParams = {}) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Communication/MarkChatAsReadByUser`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationCreateChatCreate
     * @request POST:/api/Communication/CreateChat
     * @secure
     */
    communicationCreateChatCreate: (data: CreateChatRequest, params: RequestParams = {}) =>
      this.request<GroupChatViewObjectClientResponse, any>({
        path: `/api/Communication/CreateChat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationDeleteChatCreate
     * @request POST:/api/Communication/DeleteChat
     * @secure
     */
    communicationDeleteChatCreate: (data: DeleteChatRequest, params: RequestParams = {}) =>
      this.request<GroupChatViewObjectClientResponse, any>({
        path: `/api/Communication/DeleteChat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationRenameChatCreate
     * @request POST:/api/Communication/RenameChat
     * @secure
     */
    communicationRenameChatCreate: (data: RenameChatRequest, params: RequestParams = {}) =>
      this.request<GroupChatViewObjectClientResponse, any>({
        path: `/api/Communication/RenameChat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationAddUsersToChatCreate
     * @request POST:/api/Communication/AddUsersToChat
     * @secure
     */
    communicationAddUsersToChatCreate: (data: AddUsersToChatRequest, params: RequestParams = {}) =>
      this.request<GroupChatViewObjectClientResponse, any>({
        path: `/api/Communication/AddUsersToChat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationRemoveUsersFromChatCreate
     * @request POST:/api/Communication/RemoveUsersFromChat
     * @secure
     */
    communicationRemoveUsersFromChatCreate: (data: RemoveUserFromChatRequest, params: RequestParams = {}) =>
      this.request<GroupChatViewObjectClientResponse, any>({
        path: `/api/Communication/RemoveUsersFromChat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationGetChatList
     * @request GET:/api/Communication/GetChat
     * @secure
     */
    communicationGetChatList: (
      query: {
        /** @format uuid */
        chatId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GroupChatViewObjectClientResponse, any>({
        path: `/api/Communication/GetChat`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationGetChatsList
     * @request GET:/api/Communication/GetChats
     * @secure
     */
    communicationGetChatsList: (params: RequestParams = {}) =>
      this.request<GroupChatViewModelClientResponse, any>({
        path: `/api/Communication/GetChats`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationGetMessagesForChatList
     * @request GET:/api/Communication/GetMessagesForChat
     * @secure
     */
    communicationGetMessagesForChatList: (
      query: {
        /** @format uuid */
        chatId: string;
        /** @format int32 */
        skip: number;
        /** @format int32 */
        take: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GroupChatMessagesViewModelClientResponse, any>({
        path: `/api/Communication/GetMessagesForChat`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerCustomersList
     * @request GET:/api/Customer/Customers
     * @secure
     */
    customerCustomersList: (params: RequestParams = {}) =>
      this.request<CustomersViewModelClientResponse, any>({
        path: `/api/Customer/Customers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetUserList
     * @request GET:/api/Customer/GetUser
     * @secure
     */
    customerGetUserList: (
      query: {
        /** @format uuid */
        customerId: string;
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerUserViewModelClientResponse, any>({
        path: `/api/Customer/GetUser`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetCustomerShipmentTypesList
     * @request GET:/api/Customer/GetCustomerShipmentTypes
     * @secure
     */
    customerGetCustomerShipmentTypesList: (
      query: {
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerShipmentTypeViewObjectArrayClientResponse, any>({
        path: `/api/Customer/GetCustomerShipmentTypes`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetCustomerShipmentTypesCreate
     * @request POST:/api/Customer/GetCustomerShipmentTypes
     * @secure
     */
    customerGetCustomerShipmentTypesCreate: (data: CustomerShipmentTypesRequest, params: RequestParams = {}) =>
      this.request<CustomerShipmentTypesViewModelClientResponse, any>({
        path: `/api/Customer/GetCustomerShipmentTypes`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetDeliveryFeesList
     * @request GET:/api/Customer/GetDeliveryFees
     * @secure
     */
    customerGetDeliveryFeesList: (
      query: {
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerDeliveryFeeViewObjectArrayClientResponse, any>({
        path: `/api/Customer/GetDeliveryFees`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetDeliveryFeesForCustomersCreate
     * @request POST:/api/Customer/GetDeliveryFeesForCustomers
     * @secure
     */
    customerGetDeliveryFeesForCustomersCreate: (data: CustomerDeliveryFeesRequest, params: RequestParams = {}) =>
      this.request<CustomerDeliveryFeeViewObjectArrayClientResponse, any>({
        path: `/api/Customer/GetDeliveryFeesForCustomers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetCustomerShipmentTypeCategoriesCreate
     * @request POST:/api/Customer/GetCustomerShipmentTypeCategories
     * @secure
     */
    customerGetCustomerShipmentTypeCategoriesCreate: (
      data: CustomerShipmentTypeCategoriesRequest,
      params: RequestParams = {},
    ) =>
      this.request<CustomerShipmentTypeCategoriesViewModelClientResponse, any>({
        path: `/api/Customer/GetCustomerShipmentTypeCategories`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetCustomerShipmentTypeCategoriesList
     * @request GET:/api/Customer/GetCustomerShipmentTypeCategories
     * @secure
     */
    customerGetCustomerShipmentTypeCategoriesList: (
      query: {
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerShipmentTypeCategoryViewObjectArrayClientResponse, any>({
        path: `/api/Customer/GetCustomerShipmentTypeCategories`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetCustomerDeliveryInformationTemplatesList
     * @request GET:/api/Customer/GetCustomerDeliveryInformationTemplates
     * @secure
     */
    customerGetCustomerDeliveryInformationTemplatesList: (
      query: {
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerDeliveryInformationTemplateViewObjectArrayClientResponse, any>({
        path: `/api/Customer/GetCustomerDeliveryInformationTemplates`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerAddOrUpdateCustomerDeliveryInformationTemplateCreate
     * @request POST:/api/Customer/AddOrUpdateCustomerDeliveryInformationTemplate
     * @secure
     */
    customerAddOrUpdateCustomerDeliveryInformationTemplateCreate: (
      data: AddOrUpdateCustomerDeliveryInformationTemplateRequest,
      params: RequestParams = {},
    ) =>
      this.request<CustomerDeliveryInformationTemplateViewObjectClientResponse, any>({
        path: `/api/Customer/AddOrUpdateCustomerDeliveryInformationTemplate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerDeleteCustomerDeliveryInformationTemplateDelete
     * @request DELETE:/api/Customer/DeleteCustomerDeliveryInformationTemplate
     * @secure
     */
    customerDeleteCustomerDeliveryInformationTemplateDelete: (
      query: {
        /** @format uuid */
        customerId: string;
        /** @format uuid */
        templateId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerDeliveryInformationTemplateViewObjectClientResponse, any>({
        path: `/api/Customer/DeleteCustomerDeliveryInformationTemplate`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryGetSupportedLanguagesList
     * @request GET:/api/Dictionary/GetSupportedLanguages
     * @secure
     */
    dictionaryGetSupportedLanguagesList: (params: RequestParams = {}) =>
      this.request<DictionaryLanguagesViewModelClientResponse, any>({
        path: `/api/Dictionary/GetSupportedLanguages`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryGetDictionaryLabelsForAreaList
     * @request GET:/api/Dictionary/GetDictionaryLabelsForArea
     * @secure
     */
    dictionaryGetDictionaryLabelsForAreaList: (
      query?: {
        area?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DictionaryLabelsViewModelClientResponse, any>({
        path: `/api/Dictionary/GetDictionaryLabelsForArea`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryMarkLabelAsMissingCreate
     * @request POST:/api/Dictionary/MarkLabelAsMissing
     * @secure
     */
    dictionaryMarkLabelAsMissingCreate: (data: MarkLabelAsMissingRequest, params: RequestParams = {}) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Dictionary/MarkLabelAsMissing`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GeoCoding
     * @name GeoCodingReverseList
     * @request GET:/api/GeoCoding/Reverse
     * @secure
     */
    geoCodingReverseList: (
      query: {
        /** @format double */
        latitude: number;
        /** @format double */
        longitude: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GeoLocationAddressClientResponse, any>({
        path: `/api/GeoCoding/Reverse`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GeoCoding
     * @name GeoCodingForwardList
     * @request GET:/api/GeoCoding/Forward
     * @secure
     */
    geoCodingForwardList: (
      query: {
        street: string;
        /** @format int32 */
        postalCode: number;
        city: string;
        country: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GeoLocationAddressClientResponse, any>({
        path: `/api/GeoCoding/Forward`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPS
     * @name GpsReverseList
     * @request GET:/api/GPS/Reverse
     * @secure
     */
    gpsReverseList: (
      query: {
        /** @format double */
        latitude: number;
        /** @format double */
        longitude: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GeoLocationAddressClientResponse, any>({
        path: `/api/GPS/Reverse`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPS
     * @name GpsForwardList
     * @request GET:/api/GPS/Forward
     * @secure
     */
    gpsForwardList: (
      query: {
        street: string;
        /** @format int32 */
        postalCode: number;
        city: string;
        country: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GeoLocationAddressClientResponse, any>({
        path: `/api/GPS/Forward`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPS
     * @name GpsGetDirectionsCreate
     * @request POST:/api/GPS/GetDirections
     * @secure
     */
    gpsGetDirectionsCreate: (data: DirectionsRequest, params: RequestParams = {}) =>
      this.request<GPSDirectionsClientResponse, any>({
        path: `/api/GPS/GetDirections`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPS
     * @name GpsGetOptimizedDirectionsCreate
     * @request POST:/api/GPS/GetOptimizedDirections
     * @secure
     */
    gpsGetOptimizedDirectionsCreate: (data: DirectionsRequest, params: RequestParams = {}) =>
      this.request<GPSDirectionsClientResponse, any>({
        path: `/api/GPS/GetOptimizedDirections`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Image
     * @name ImageUploadAndReplaceImageUpdate
     * @request PUT:/api/Image/UploadAndReplaceImage
     * @secure
     */
    imageUploadAndReplaceImageUpdate: (
      query: {
        currentImageUrl?: string;
        /** @format uuid */
        identifier: string;
      },
      data: {
        /** @format binary */
        formFile: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<ImageUploadResponseClientResponse, any>({
        path: `/api/Image/UploadAndReplaceImage`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Image
     * @name ImageDeleteImageDelete
     * @request DELETE:/api/Image/DeleteImage
     * @secure
     */
    imageDeleteImageDelete: (
      query: {
        imageUrl: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Image/DeleteImage`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Image
     * @name ImageDeleteImagesDelete
     * @request DELETE:/api/Image/DeleteImages
     * @secure
     */
    imageDeleteImagesDelete: (data: string[], params: RequestParams = {}) =>
      this.request<BooleanArrayClientResponse, any>({
        path: `/api/Image/DeleteImages`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags IpInfo
     * @name IpInfoGetInfoList
     * @request GET:/api/IpInfo/GetInfo
     * @secure
     */
    ipInfoGetInfoList: (
      query: {
        ip: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IpInformationViewObjectClientResponse, any>({
        path: `/api/IpInfo/GetInfo`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsMarkNotificationAsSeenCreate
     * @request POST:/api/Notifications/MarkNotificationAsSeen
     * @secure
     */
    notificationsMarkNotificationAsSeenCreate: (
      query: {
        /** @format uuid */
        notificationId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Notifications/MarkNotificationAsSeen`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsGetActiveNotificationsList
     * @request GET:/api/Notifications/GetActiveNotifications
     * @secure
     */
    notificationsGetActiveNotificationsList: (
      query: {
        target: NotificationTarget;
      },
      params: RequestParams = {},
    ) =>
      this.request<NotificationViewModelClientResponse, any>({
        path: `/api/Notifications/GetActiveNotifications`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Resource
     * @name ResourceGetResourcesList
     * @request GET:/api/Resource/GetResources
     * @secure
     */
    resourceGetResourcesList: (params: RequestParams = {}) =>
      this.request<ResourcesViewModelClientResponse, any>({
        path: `/api/Resource/GetResources`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Resource
     * @name ResourceGetResourceCategoriesList
     * @request GET:/api/Resource/GetResourceCategories
     * @secure
     */
    resourceGetResourceCategoriesList: (params: RequestParams = {}) =>
      this.request<ResourceCategoriesViewModelClientResponse, any>({
        path: `/api/Resource/GetResourceCategories`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Resource
     * @name ResourceGetDriverLogsForResourceList
     * @request GET:/api/Resource/GetDriverLogsForResource
     * @secure
     */
    resourceGetDriverLogsForResourceList: (
      query: {
        /** @format uuid */
        resourceId: string;
        /** @format date-time */
        fromDate: string;
        /** @format date-time */
        toDate: string;
        /** @format int32 */
        skip: number;
        /** @format int32 */
        take: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityResourceDriverLogsViewModelClientResponse, any>({
        path: `/api/Resource/GetDriverLogsForResource`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ScheduleGetSchedulesList
     * @request GET:/api/Schedule/GetSchedules
     * @secure
     */
    scheduleGetSchedulesList: (
      query: {
        /** @format date-time */
        from: string;
        /** @format date-time */
        to: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SchedulesEntryViewModelClientResponse, any>({
        path: `/api/Schedule/GetSchedules`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ScheduleDownloadTaskScheduleOverviewPdfCreate
     * @request POST:/api/Schedule/DownloadTaskScheduleOverviewPdf
     * @secure
     */
    scheduleDownloadTaskScheduleOverviewPdfCreate: (
      query: {
        /** @format date-time */
        scheduleFrom: string;
        /** @format date-time */
        scheduleTo: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Schedule/DownloadTaskScheduleOverviewPdf`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shipment
     * @name ShipmentSearchPostalCodeList
     * @request GET:/api/Shipment/SearchPostalCode
     * @secure
     */
    shipmentSearchPostalCodeList: (
      query?: {
        query?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PostalCodeSearchResponseClientResponse, any>({
        path: `/api/Shipment/SearchPostalCode`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shipment
     * @name ShipmentSearchAddressesList
     * @request GET:/api/Shipment/SearchAddresses
     * @secure
     */
    shipmentSearchAddressesList: (
      query?: {
        query?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SearchAddressesResponseClientResponse, any>({
        path: `/api/Shipment/SearchAddresses`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shipment
     * @name ShipmentDownloadShipmentNoticePdfCreate
     * @request POST:/api/Shipment/DownloadShipmentNoticePdf
     * @secure
     */
    shipmentDownloadShipmentNoticePdfCreate: (
      query: {
        /** @format uuid */
        taskId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Shipment/DownloadShipmentNoticePdf`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Storages
     * @name StoragesGetStoragesCreate
     * @request POST:/api/Storages/GetStorages
     * @secure
     */
    storagesGetStoragesCreate: (params: RequestParams = {}) =>
      this.request<BusinessEntityStoragesViewModelClientResponse, any>({
        path: `/api/Storages/GetStorages`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetDriverLogsList
     * @request GET:/api/Task/GetDriverLogs
     * @secure
     */
    taskGetDriverLogsList: (
      query: {
        /** @format date-time */
        from: string;
        /** @format date-time */
        to: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityUserDriverLogsViewModelClientResponse, any>({
        path: `/api/Task/GetDriverLogs`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetActiveDriverLogList
     * @request GET:/api/Task/GetActiveDriverLog
     * @secure
     */
    taskGetActiveDriverLogList: (params: RequestParams = {}) =>
      this.request<BusinessEntityUserDriverLogViewObjectClientResponse, any>({
        path: `/api/Task/GetActiveDriverLog`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskStartDriverLogCreate
     * @request POST:/api/Task/StartDriverLog
     * @secure
     */
    taskStartDriverLogCreate: (data: StartDriverLogRequest, params: RequestParams = {}) =>
      this.request<BusinessEntityUserDriverLogViewObjectClientResponse, any>({
        path: `/api/Task/StartDriverLog`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskCompleteDriverLogCreate
     * @request POST:/api/Task/CompleteDriverLog
     * @secure
     */
    taskCompleteDriverLogCreate: (
      data: DriverLogRequest | StartDriverLogRequest | StartDriverLogBreakRequest,
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityUserDriverLogViewObjectClientResponse, any>({
        path: `/api/Task/CompleteDriverLog`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskStartDriverLogBreakCreate
     * @request POST:/api/Task/StartDriverLogBreak
     * @secure
     */
    taskStartDriverLogBreakCreate: (data: StartDriverLogBreakRequest, params: RequestParams = {}) =>
      this.request<BusinessEntityUserDriverLogViewObjectClientResponse, any>({
        path: `/api/Task/StartDriverLogBreak`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskStopDriverLogBreakCreate
     * @request POST:/api/Task/StopDriverLogBreak
     * @secure
     */
    taskStopDriverLogBreakCreate: (
      data: DriverLogRequest | StartDriverLogRequest | StartDriverLogBreakRequest,
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityUserDriverLogViewObjectClientResponse, any>({
        path: `/api/Task/StopDriverLogBreak`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetTaskScheduleList
     * @request GET:/api/Task/GetTaskSchedule
     * @secure
     */
    taskGetTaskScheduleList: (
      query: {
        /** @format date-time */
        startDate: string;
        /** @format date-time */
        endDate: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TasksViewModelClientResponse, any>({
        path: `/api/Task/GetTaskSchedule`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetTaskByIdList
     * @request GET:/api/Task/GetTaskById
     * @secure
     */
    taskGetTaskByIdList: (
      query: {
        /** @format uuid */
        id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/GetTaskById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetTasksByIdCreate
     * @request POST:/api/Task/GetTasksById
     * @secure
     */
    taskGetTasksByIdCreate: (data: TasksByIdRequest, params: RequestParams = {}) =>
      this.request<TasksViewModelClientResponse, any>({
        path: `/api/Task/GetTasksById`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetTaskCountForIntervalList
     * @request GET:/api/Task/GetTaskCountForInterval
     * @secure
     */
    taskGetTaskCountForIntervalList: (
      query: {
        /** @format date-time */
        start: string;
        /** @format date-time */
        end: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TaskStatisticsDateEntryViewObjectArrayClientResponse, any>({
        path: `/api/Task/GetTaskCountForInterval`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskMarkTaskStepAsNotifiedCreate
     * @request POST:/api/Task/MarkTaskStepAsNotified
     * @secure
     */
    taskMarkTaskStepAsNotifiedCreate: (data: MarkTaskStepsAsNotifiedRequest, params: RequestParams = {}) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/MarkTaskStepAsNotified`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskStartTaskCreate
     * @request POST:/api/Task/StartTask
     * @secure
     */
    taskStartTaskCreate: (data: TaskProgressRequest | CompleteTaskRequest, params: RequestParams = {}) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/StartTask`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskCompleteTaskCreate
     * @request POST:/api/Task/CompleteTask
     * @secure
     */
    taskCompleteTaskCreate: (data: CompleteTaskRequest, params: RequestParams = {}) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/CompleteTask`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskReturnTaskToInProgressCreate
     * @request POST:/api/Task/ReturnTaskToInProgress
     * @secure
     */
    taskReturnTaskToInProgressCreate: (data: TaskProgressRequest | CompleteTaskRequest, params: RequestParams = {}) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/ReturnTaskToInProgress`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskPlaceDeliveryInStorageCreate
     * @request POST:/api/Task/PlaceDeliveryInStorage
     * @secure
     */
    taskPlaceDeliveryInStorageCreate: (
      query: {
        /** @format uuid */
        taskId: string;
        /** @format uuid */
        storageId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/PlaceDeliveryInStorage`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskRemoveDeliveryFromStorageCreate
     * @request POST:/api/Task/RemoveDeliveryFromStorage
     * @secure
     */
    taskRemoveDeliveryFromStorageCreate: (data: string, params: RequestParams = {}) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/RemoveDeliveryFromStorage`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskAddOrUpdateDeviationCreate
     * @request POST:/api/Task/AddOrUpdateDeviation
     * @secure
     */
    taskAddOrUpdateDeviationCreate: (data: AddOrUpdateDeviationRequest, params: RequestParams = {}) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/AddOrUpdateDeviation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskRemoveDeviationDelete
     * @request DELETE:/api/Task/RemoveDeviation
     * @secure
     */
    taskRemoveDeviationDelete: (
      query: {
        /** @format uuid */
        taskId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/RemoveDeviation`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskAddOrUpdateImageInformationCreate
     * @request POST:/api/Task/AddOrUpdateImageInformation
     * @secure
     */
    taskAddOrUpdateImageInformationCreate: (
      query: {
        /** @format uuid */
        taskId: string;
        type: DeliveryStepType;
      },
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<TaskViewModelClientResponse, any>({
        path: `/api/Task/AddOrUpdateImageInformation`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetTasksForDriverLogsCreate
     * @request POST:/api/Task/GetTasksForDriverLogs
     * @secure
     */
    taskGetTasksForDriverLogsCreate: (data: TasksForDriverLogRequest, params: RequestParams = {}) =>
      this.request<TasksViewModelClientResponse, any>({
        path: `/api/Task/GetTasksForDriverLogs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetDriverLogByIdList
     * @request GET:/api/Task/GetDriverLogById
     * @secure
     */
    taskGetDriverLogByIdList: (
      query: {
        /** @format uuid */
        driverLogId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityUserDriverLogViewObjectClientResponse, any>({
        path: `/api/Task/GetDriverLogById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetDriverLogsByIdList
     * @request GET:/api/Task/GetDriverLogsById
     * @secure
     */
    taskGetDriverLogsByIdList: (data: string[], params: RequestParams = {}) =>
      this.request<BusinessEntityUserDriverLogsViewModelClientResponse, any>({
        path: `/api/Task/GetDriverLogsById`,
        method: "GET",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name TaskGetTimeToStorageList
     * @request GET:/api/Task/GetTimeToStorage
     * @secure
     */
    taskGetTimeToStorageList: (
      query: {
        /** @format uuid */
        scheduleEntryId: string;
        /** @format uuid */
        storageId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DistanceSummaryClientResponse, any>({
        path: `/api/Task/GetTimeToStorage`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserGetUserLoginHistoryList
     * @request GET:/api/User/GetUserLoginHistory
     * @secure
     */
    userGetUserLoginHistoryList: (params: RequestParams = {}) =>
      this.request<BusinessEntityUserLoginHistoryViewModelClientResponse, any>({
        path: `/api/User/GetUserLoginHistory`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserTrackLocationCreate
     * @request POST:/api/User/TrackLocation
     * @secure
     */
    userTrackLocationCreate: (data: TrackLocationRequest, params: RequestParams = {}) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/User/TrackLocation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersGetUserList
     * @request GET:/api/Users/GetUser
     * @secure
     */
    usersGetUserList: (
      query: {
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BusinessEntityUserViewModelClientResponse, any>({
        path: `/api/Users/GetUser`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersGetUsersCreate
     * @request POST:/api/Users/GetUsers
     * @secure
     */
    usersGetUsersCreate: (data: UsersRequest, params: RequestParams = {}) =>
      this.request<BusinessEntityUsersViewModelClientResponse, any>({
        path: `/api/Users/GetUsers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
